<template>
  <div>
    <b-container fluid>
      <b-row v-if="premiseNotifications.length > 0" class="match-height">
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="notifications in premiseNotifications"
          :key="notifications.NoteId"
        >
          <b-card no-body>
            <b-card-body class="border-top p-1">
              <b-card-title
                ><feather-icon icon="MessageSquareIcon" />
                {{ getHumanDate(notifications.NoteDate) }}</b-card-title
              >
              <p>{{ notifications.NoteTxt }}</p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col cols="12">
          <p class="bg-white p-1 font-weight-bold">
            You have no notifications on your account.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" lg="4" xl="3">
          <b-card
            v-if="blogList"
            :img-src="blogList[1].img"
            img-alt="Card image"
            img-top
          >
            <b-link
              :to="{
                name: 'news-waste-detail',
                params: { id: blogList[1].id },
              }"
            >
              <b-card-text class="h-100">
                <strong>{{ blogList[1].title }}</strong>
              </b-card-text>
            </b-link>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
import store from "@/store/index";
import moment from "moment";
import {
  BContainer,
  BRow,
  BButton,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BRow,
    BButton,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
  },
  data() {
    return {
      blogList: null,
    };
  },
  computed: {
    premiseNotifications: function () {
      return store.state.app.selectedPremiseNotifications;
    },
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getBlogPosts: function () {
      this.$http.get("/blog/list/data").then((res) => {
        this.blogList = res.data;
      });
    },
  },
  mounted: function () {
    this.getBlogPosts();
  },
};
</script>
